import { NgDocSearchEngine } from '@ng-doc/app/classes/search-engine';

/**
 * Provides the `NgDocSearchEngine` to enable search in the documentation.
 *
 * You can create and provide your own `NgDocSearchEngine` if you want to handle the search yourself.
 * @param engine - The search engine class.
 * @param args - The arguments for the search engine class.
 * @returns The provider for the search engine.
 */
function provideSearchEngine(engine, ...args) {
  return {
    provide: NgDocSearchEngine,
    useValue: new engine(...args)
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { provideSearchEngine };
