import { NG_DOC_PAGE_SKELETON } from '@ng-doc/app/tokens';

/**
 * Provides the skeleton components for the page.
 * @param skeleton - The skeleton for the page.
 */
function providePageSkeleton(skeleton) {
  return [{
    provide: NG_DOC_PAGE_SKELETON,
    useValue: skeleton
  }];
}

/**
 * Generated bundle index. Do not edit.
 */

export { providePageSkeleton };
