// @ts-nocheck
import {Provider} from '@angular/core';
import {NG_DOC_CONTEXT} from '@ng-doc/app/tokens';

export function provideNgDocContext(): Provider {
    return {
        provide: NG_DOC_CONTEXT,
        useValue: {
            navigation: [
                
                {
                    title: `Getting Started`,
                    route: '/getting-started',
                    expandable: true,
                    expanded: false,
                    order: 1,
                    hidden: false,
                    children: [
                        
                {
                    title: `Introduction`,
                    route: '/getting-started/introduction',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `Installation`,
                    route: '/getting-started/installation',
                    order: 2,
                    hidden: false,
                },
                        
                {
                    title: `Contributing`,
                    route: '/getting-started/contributing',
                    order: 3,
                    hidden: false,
                },
                    ]
                },
                
                {
                    title: `Components`,
                    route: '/components',
                    expandable: true,
                    expanded: false,
                    order: 2,
                    hidden: false,
                    children: [
                        
                {
                    title: `Alert`,
                    route: '/components/alert',
                    expandable: true,
                    expanded: false,
                    order: 1,
                    hidden: false,
                    children: [
                        
                {
                    title: `Examples`,
                    route: '/components/alert/examples',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `API`,
                    route: '/components/alert/alert',
                    hidden: false,
                },
                    ]
                },
                        
                {
                    title: `Badge`,
                    route: '/components/badge',
                    expandable: true,
                    expanded: false,
                    order: 2,
                    hidden: false,
                    children: [
                        
                {
                    title: `Examples`,
                    route: '/components/badge/examples',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `API`,
                    route: '/components/badge/badge',
                    hidden: false,
                },
                    ]
                },
                        
                {
                    title: `Button`,
                    route: '/components/button',
                    expandable: true,
                    expanded: false,
                    order: 3,
                    hidden: false,
                    children: [
                        
                {
                    title: `Examples`,
                    route: '/components/button/examples',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `API`,
                    route: '/components/button/button',
                    hidden: false,
                },
                    ]
                },
                        
                {
                    title: `Card`,
                    route: '/components/card',
                    expandable: true,
                    expanded: false,
                    order: 4,
                    hidden: false,
                    children: [
                        
                {
                    title: `Examples`,
                    route: '/components/card/examples',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `API`,
                    route: '/components/card/card',
                    hidden: false,
                },
                    ]
                },
                        
                {
                    title: `Divider`,
                    route: '/components/divider',
                    expandable: true,
                    expanded: false,
                    order: 5,
                    hidden: false,
                    children: [
                        
                {
                    title: `Examples`,
                    route: '/components/divider/examples',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `API`,
                    route: '/components/divider/divider',
                    hidden: false,
                },
                    ]
                },
                        
                {
                    title: `Loader`,
                    route: '/components/loader',
                    expandable: true,
                    expanded: false,
                    order: 6,
                    hidden: false,
                    children: [
                        
                {
                    title: `Examples`,
                    route: '/components/loader/examples',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `API`,
                    route: '/components/loader/loader',
                    hidden: false,
                },
                    ]
                },
                        
                {
                    title: `Logo`,
                    route: '/components/logo',
                    expandable: true,
                    expanded: false,
                    order: 7,
                    hidden: false,
                    children: [
                        
                {
                    title: `Examples`,
                    route: '/components/logo/examples',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `API`,
                    route: '/components/logo/logo',
                    hidden: false,
                },
                    ]
                },
                        
                {
                    title: `Layout`,
                    route: '/components/navigation',
                    expandable: true,
                    expanded: false,
                    order: 8,
                    hidden: false,
                    children: [
                        
                {
                    title: `Examples`,
                    route: '/components/navigation/examples',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `API`,
                    route: '/components/navigation/layout',
                    hidden: false,
                },
                    ]
                },
                        
                {
                    title: `Search Input`,
                    route: '/components/search-input',
                    expandable: true,
                    expanded: false,
                    order: 9,
                    hidden: false,
                    children: [
                        
                {
                    title: `Examples`,
                    route: '/components/search-input/examples',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `API`,
                    route: '/components/search-input/search-input',
                    hidden: false,
                },
                    ]
                },
                        
                {
                    title: `Skeleton`,
                    route: '/components/skeleton',
                    expandable: true,
                    expanded: false,
                    order: 10,
                    hidden: false,
                    children: [
                        
                {
                    title: `Examples`,
                    route: '/components/skeleton/examples',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `API`,
                    route: '/components/skeleton/skeleton',
                    hidden: false,
                },
                    ]
                },
                        
                {
                    title: `Status Tag`,
                    route: '/components/status-tag',
                    expandable: true,
                    expanded: false,
                    order: 11,
                    hidden: false,
                    children: [
                        
                {
                    title: `Examples`,
                    route: '/components/status-tag/examples',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `API`,
                    route: '/components/status-tag/status-tag',
                    hidden: false,
                },
                    ]
                },
                        
                {
                    title: `Switch`,
                    route: '/components/switch',
                    expandable: true,
                    expanded: false,
                    order: 12,
                    hidden: false,
                    children: [
                        
                {
                    title: `Examples`,
                    route: '/components/switch/examples',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `API`,
                    route: '/components/switch/switch',
                    hidden: false,
                },
                    ]
                },
                        
                {
                    title: `Theme Manager`,
                    route: '/components/theme-manager',
                    expandable: true,
                    expanded: false,
                    order: 13,
                    hidden: false,
                    children: [
                        
                {
                    title: `Examples`,
                    route: '/components/theme-manager/examples',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `API`,
                    route: '/components/theme-manager/toast',
                    hidden: false,
                },
                    ]
                },
                        
                {
                    title: `Toast`,
                    route: '/components/toast',
                    expandable: true,
                    expanded: false,
                    order: 14,
                    hidden: false,
                    children: [
                        
                {
                    title: `Examples`,
                    route: '/components/toast/examples',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `API`,
                    route: '/components/toast/toast',
                    hidden: false,
                },
                    ]
                },
                    ]
                },
                
                {
                    title: `Directives`,
                    route: '/directives',
                    expandable: true,
                    expanded: false,
                    order: 3,
                    hidden: false,
                    children: [
                        
                {
                    title: `ClickOutside`,
                    route: '/directives/click-outside',
                    order: 1,
                    hidden: false,
                },
                    ]
                },
                
                {
                    title: `Pipes`,
                    route: '/pipes',
                    expandable: true,
                    expanded: false,
                    order: 4,
                    hidden: false,
                    children: [
                        
                {
                    title: `VmrMemoizeFnPipe`,
                    route: '/pipes/memoize-fn',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `VmrRepeatPipe`,
                    route: '/pipes/repeat',
                    order: 2,
                    hidden: false,
                },
                    ]
                },
                
                {
                    title: `Signals`,
                    route: '/signals',
                    expandable: true,
                    expanded: false,
                    order: 5,
                    hidden: false,
                    children: [
                        
                {
                    title: `BreakpointObserver`,
                    route: '/signals/breakpoint-observer',
                    order: 1,
                    hidden: false,
                },
                        
                {
                    title: `computedPrevious`,
                    route: '/signals/computed-previous',
                    order: 2,
                    hidden: false,
                },
                        
                {
                    title: `explicitEffect`,
                    route: '/signals/explicit-effect',
                    order: 3,
                    hidden: false,
                },
                    ]
                },
            ],
        }
    };
}
