/**
 * Abstract search engine class, that can be used to implement a custom search engine.
 */
class NgDocSearchEngine {}

/**
 * Generated bundle index. Do not edit.
 */

export { NgDocSearchEngine };
