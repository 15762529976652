export const OBJECT_COMPONENTS = ['tokenizer', 'index', 'documentsStore', 'sorter'];
export const FUNCTION_COMPONENTS = ['validateSchema', 'getDocumentIndexId', 'getDocumentProperties', 'formatElapsedTime'];
export const SINGLE_OR_ARRAY_COMPONENTS = ['beforeInsert', 'afterInsert', 'beforeRemove', 'afterRemove', 'beforeUpdate', 'afterUpdate', 'beforeMultipleInsert', 'afterMultipleInsert', 'beforeMultipleRemove', 'afterMultipleRemove', 'beforeMultipleUpdate', 'afterMultipleUpdate'];
export async function runSingleHook(hooks, orama, id, doc) {
  for (let i = 0; i < hooks.length; i++) {
    await hooks[i](orama, id, doc);
  }
}
export async function runMultipleHook(hooks, orama, docsOrIds) {
  for (let i = 0; i < hooks.length; i++) {
    await hooks[i](orama, docsOrIds);
  }
}

