import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withInMemoryScrolling, withViewTransitions } from '@angular/router';
import { DOCS_APP_LIGHT_THEME } from './core/constants';
import {
  provideNgDocApp,
  providePageSkeleton,
  provideSearchEngine,
  NgDocDefaultSearchEngine,
  provideMainPageProcessor,
  NG_DOC_DEFAULT_PAGE_SKELETON,
  NG_DOC_DEFAULT_PAGE_PROCESSORS
} from '@ng-doc/app';
import { provideNgDocContext } from '@ng-doc/generated';
import { ROUTES } from './routes';
import { provideVmrThemeManager } from '@vermeer-corp/it-ng-components/theme-manager';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      ROUTES,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled'
      }),
      withViewTransitions()
    ),
    provideNgDocContext(),
    provideNgDocApp({
      defaultThemeId: DOCS_APP_LIGHT_THEME,
      themes: [
        {
          id: DOCS_APP_LIGHT_THEME,
          path: `assets/themes/${DOCS_APP_LIGHT_THEME}.css`
        }
      ]
    }),
    provideVmrThemeManager({
      track: false,
      useStorage: false,
      themeDefault: 'light'
    }),
    provideSearchEngine(NgDocDefaultSearchEngine),
    providePageSkeleton(NG_DOC_DEFAULT_PAGE_SKELETON),
    provideMainPageProcessor(NG_DOC_DEFAULT_PAGE_PROCESSORS),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimationsAsync()
  ]
};
