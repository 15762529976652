import { __decorate, __metadata } from 'tslib';
import { DOCUMENT } from '@angular/common';
import * as i0 from '@angular/core';
import { afterNextRender, Injectable, Inject } from '@angular/core';
import { NG_DOC_STORE_THEME_KEY } from '@ng-doc/app/constants';
import * as i1 from '@ng-doc/app/services/store';
import { NgDocStoreService } from '@ng-doc/app/services/store';
import { NG_DOC_THEME } from '@ng-doc/app/tokens';
import { isBrowser } from '@ng-doc/core';
import { WINDOW } from '@ng-web-apis/common';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Subject, fromEvent } from 'rxjs';
var NgDocThemeService_1;
/**
 * Service for managing themes.
 */
let NgDocThemeService = class NgDocThemeService {
  static {
    NgDocThemeService_1 = this;
  }
  static {
    this.autoThemeId = 'ng-doc-auto';
  }
  constructor(window, document, themes, store) {
    this.window = window;
    this.document = document;
    this.themes = themes;
    this.store = store;
    this.theme = undefined;
    this.theme$ = new Subject();
    this.autoTheme = undefined;
    afterNextRender(() => {
      fromEvent(this.window.matchMedia('(prefers-color-scheme: dark)'), 'change').pipe(untilDestroyed(this)).subscribe(() => this.setAutoTheme());
    });
  }
  /**
   * Returns the current theme.
   */
  get currentTheme() {
    return this.theme;
  }
  /**
   * Returns whether automatic theme switching based on the user's operating system settings is enabled.
   */
  get isAutoThemeEnabled() {
    return this.autoTheme !== undefined;
  }
  /**
   * Enables automatic theme switching based on the user's operating system settings.
   * @param light - Theme for light mode.
   * @param dark - Theme for dark mode.
   */
  async enableAutoTheme(light, dark) {
    this.autoTheme = [light, dark];
    return this.setAutoTheme();
  }
  /**
   * Disables automatic theme switching based on the user's operating system settings.
   */
  async disableAutoTheme() {
    this.autoTheme = undefined;
    return this.set(this.store.get(NG_DOC_STORE_THEME_KEY) ?? undefined);
  }
  /**
   * Sets the theme by id.
   * @param id - Theme id.
   * @param save - Whether to save the theme in the store to restore it when the page is reloaded. (`true` by default)
   */
  async set(id, save = true) {
    this.removeLink();
    if (save) {
      this.autoTheme = undefined;
    }
    if (id && id !== 'ng-doc-day') {
      const theme = this.themes.find(theme => theme.id === id);
      if (!theme) {
        console.warn(`Theme with id "${id}" is not registered. Make sure that you registered it in the root of your application.`);
        return;
      }
      this.createLinkIfNoExists();
      if (this.linkElement) {
        this.linkElement.href = theme.path;
        save && this.store.set(NG_DOC_STORE_THEME_KEY, theme.id);
        this.theme = theme;
        return new Promise((resolve, reject) => {
          if (this.linkElement) {
            this.linkElement.onload = () => {
              this.theme$.next(theme);
              resolve();
            };
            this.linkElement.onerror = reject;
          }
        });
      }
    }
    save && this.store.set(NG_DOC_STORE_THEME_KEY, 'ng-doc-day');
    this.theme$.next(undefined);
    return Promise.resolve();
  }
  /**
   * Returns an observable that emits when the theme changes.
   */
  themeChanges() {
    return this.theme$.asObservable();
  }
  removeLink() {
    this.theme = undefined;
    this.linkElement?.remove();
    this.linkElement = undefined;
  }
  createLinkIfNoExists() {
    if (!this.linkElement) {
      this.linkElement = this.document.createElement('link');
      this.linkElement.setAttribute('rel', 'stylesheet');
      this.linkElement.setAttribute('type', 'text/css');
      this.document.getElementsByTagName('head')[0].appendChild(this.linkElement);
    }
  }
  async setAutoTheme() {
    if (this.autoTheme !== undefined && isBrowser) {
      const isDark = this.window.matchMedia('(prefers-color-scheme: dark)').matches;
      const [light, dark] = this.autoTheme;
      this.store.set(NG_DOC_STORE_THEME_KEY, NgDocThemeService_1.autoThemeId);
      return this.set(isDark ? dark?.id : light?.id, false);
    }
  }
  static {
    this.ɵfac = function NgDocThemeService_Factory(t) {
      return new (t || NgDocThemeService)(i0.ɵɵinject(WINDOW), i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(NG_DOC_THEME), i0.ɵɵinject(i1.NgDocStoreService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgDocThemeService,
      factory: NgDocThemeService.ɵfac,
      providedIn: 'root'
    });
  }
};
NgDocThemeService = NgDocThemeService_1 = __decorate([UntilDestroy(), __metadata("design:paramtypes", [Window, Document, Array, NgDocStoreService])], NgDocThemeService);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && void 0;
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NgDocThemeService };
