import { InjectionToken } from '@angular/core';
const tokenStore = new Map();
/**
 *
 * @param type
 * @param control
 * @param options
 * @param playgroundId
 * @param selector
 * @param component
 */
function providePlaygroundDemo(playgroundId, component) {
  const token = tokenStore.get(playgroundId) ?? new InjectionToken(`NG_DOC_PLAYGROUND_DEMO_${playgroundId}`);
  tokenStore.set(playgroundId, token);
  return {
    provide: token,
    useValue: component,
    multi: true
  };
}
/**
 * Returns the token for the given playground id.
 * @param playgroundId
 */
function getPlaygroundDemoToken(playgroundId) {
  return tokenStore.get(playgroundId);
}

/**
 * Generated bundle index. Do not edit.
 */

export { getPlaygroundDemoToken, providePlaygroundDemo };
