// @ts-nocheck
import {Routes} from '@angular/router';

export const NG_DOC_ROUTING: Routes = [
    {
        path: 'components',
        loadChildren: () => import('./guides/components/module')
    },
    {
        path: 'directives',
        loadChildren: () => import('./guides/directives/module')
    },
    {
        path: 'getting-started',
        loadChildren: () => import('./guides/getting-started/module')
    },
    {
        path: 'pipes',
        loadChildren: () => import('./guides/pipes/module')
    },
    {
        path: 'signals',
        loadChildren: () => import('./guides/signals/module')
    },
];
