export async function create() {
  return {
    docs: {},
    count: 0
  };
}
export async function get(store, id) {
  return store.docs[id];
}
export async function getMultiple(store, ids) {
  const found = Array.from({
    length: ids.length
  });
  for (let i = 0; i < ids.length; i++) {
    found[i] = store.docs[ids[i]];
  }
  return found;
}
export async function getAll(store) {
  return store.docs;
}
export async function store(store, id, doc) {
  if (typeof store.docs[id] !== 'undefined') {
    return false;
  }
  store.docs[id] = doc;
  store.count++;
  return true;
}
export async function remove(store, id) {
  if (typeof store.docs[id] === 'undefined') {
    return false;
  }
  delete store.docs[id];
  store.count--;
  return true;
}
export async function count(store) {
  return store.count;
}
export async function load(raw) {
  const rawDocument = raw;
  return {
    docs: rawDocument.docs,
    count: rawDocument.count
  };
}
export async function save(store) {
  return {
    docs: store.docs,
    count: store.count
  };
}
export async function createDocumentsStore() {
  return {
    create,
    get,
    getMultiple,
    getAll,
    store,
    remove,
    count,
    load,
    save
  };
}

