import { createError } from '../errors.js';
function innerCreate(schema, sortableDeniedProperties, prefix) {
  const sorter = {
    enabled: true,
    sortableProperties: [],
    sortablePropertiesWithTypes: {},
    sorts: {}
  };
  for (const [prop, type] of Object.entries(schema)) {
    const typeActualType = typeof type;
    const path = `${prefix}${prefix ? '.' : ''}${prop}`;
    if (sortableDeniedProperties.includes(path)) {
      continue;
    }
    if (typeActualType === 'object' && !Array.isArray(type)) {
      // Nested
      const ret = innerCreate(type, sortableDeniedProperties, path);
      sorter.sortableProperties.push(...ret.sortableProperties);
      sorter.sorts = {
        ...sorter.sorts,
        ...ret.sorts
      };
      sorter.sortablePropertiesWithTypes = {
        ...sorter.sortablePropertiesWithTypes,
        ...ret.sortablePropertiesWithTypes
      };
      continue;
    }
    switch (type) {
      case 'boolean':
      case 'number':
      case 'string':
        sorter.sortableProperties.push(path);
        sorter.sortablePropertiesWithTypes[path] = type;
        sorter.sorts[path] = {
          docs: {},
          orderedDocs: [],
          type: type,
          n: 0
        };
        break;
      case 'boolean[]':
      case 'number[]':
      case 'string[]':
        continue;
      default:
        throw createError('INVALID_SORT_SCHEMA_TYPE', Array.isArray(type) ? 'array' : type, path);
    }
  }
  return sorter;
}
async function create(_, schema, config) {
  const isSortEnabled = (config === null || config === void 0 ? void 0 : config.enabled) !== false;
  if (!isSortEnabled) {
    return {
      disabled: true
    };
  }
  return innerCreate(schema, (config || {}).unsortableProperties || [], '');
}
function stringSort(value, language, d) {
  return d[1].localeCompare(value, language) > 0;
}
function numerSort(value, d) {
  return d[1] > value;
}
function booleanSort(value, d) {
  return d[1];
}
async function insert(sorter, prop, id, value, schemaType, language) {
  if (!sorter.enabled) {
    return;
  }
  const s = sorter.sorts[prop];
  let predicate;
  switch (schemaType) {
    case 'string':
      predicate = stringSort.bind(null, value, language);
      break;
    case 'number':
      predicate = numerSort.bind(null, value);
      break;
    case 'boolean':
      predicate = booleanSort.bind(null, value);
      break;
  }
  // Find the right position to insert the element
  let index = s.orderedDocs.findIndex(predicate);
  if (index === -1) {
    index = s.orderedDocs.length;
    s.orderedDocs.push([id, value]);
  } else {
    s.orderedDocs.splice(index, 0, [id, value]);
  }
  s.docs[id] = index;
  // Increment position for the greather documents
  const orderedDocsLength = s.orderedDocs.length;
  for (let i = index + 1; i < orderedDocsLength; i++) {
    const docId = s.orderedDocs[i][0];
    s.docs[docId]++;
  }
}
async function remove(sorter, prop, id) {
  if (!sorter.enabled) {
    return;
  }
  const s = sorter.sorts[prop];
  const index = s.docs[id];
  delete s.docs[id];
  // Decrement position for the greather documents
  const orderedDocsLength = s.orderedDocs.length;
  for (let i = index + 1; i < orderedDocsLength; i++) {
    const docId = s.orderedDocs[i][0];
    s.docs[docId]--;
  }
  s.orderedDocs.splice(index, 1);
}
async function sortBy(sorter, docIds, by) {
  if (!sorter.enabled) {
    throw createError('SORT_DISABLED');
  }
  const property = by.property;
  const isDesc = by.order === 'DESC';
  const s = sorter.sorts[property];
  if (!s) {
    throw createError('UNABLE_TO_SORT_ON_UNKNOWN_FIELD', property, sorter.sortableProperties.join(', '));
  }
  const docIdsLength = docIds.length;
  // Calculate how many documents aren't inside the sorter index.
  // Used only for "DESC" sort.
  let unsortableDocumentTotal = 0;
  if (isDesc) {
    for (let i = 0; i < docIdsLength; i++) {
      if (typeof s.docs[docIds[i][0]] === 'undefined') {
        unsortableDocumentTotal++;
      }
    }
  }
  let unsortableDocumentCount = 0;
  const ret = new Array(docIdsLength);
  for (let i = 0; i < docIdsLength; i++) {
    const d = docIds[i];
    let pos = s.docs[d[0]];
    if (typeof pos === 'undefined') {
      unsortableDocumentCount++;
      pos = docIdsLength - unsortableDocumentCount;
    } else if (isDesc) {
      pos = docIdsLength - unsortableDocumentTotal - pos - 1;
    }
    ret[pos] = d;
  }
  return ret;
}
async function getSortableProperties(sorter) {
  if (!sorter.enabled) {
    return [];
  }
  return sorter.sortableProperties;
}
async function getSortablePropertiesWithTypes(sorter) {
  if (!sorter.enabled) {
    return {};
  }
  return sorter.sortablePropertiesWithTypes;
}
export async function load(raw) {
  const rawDocument = raw;
  if (!rawDocument.enabled) {
    return {
      enabled: false
    };
  }
  return {
    sortableProperties: rawDocument.sortableProperties,
    sortablePropertiesWithTypes: rawDocument.sortablePropertiesWithTypes,
    sorts: rawDocument.sorts,
    enabled: true
  };
}
export async function save(sorter) {
  if (!sorter.enabled) {
    return {
      enabled: false
    };
  }
  return {
    sortableProperties: sorter.sortableProperties,
    sortablePropertiesWithTypes: sorter.sortablePropertiesWithTypes,
    sorts: sorter.sorts,
    enabled: sorter.enabled
  };
}
export async function createSorter() {
  return {
    create,
    insert,
    remove,
    save,
    load,
    sortBy,
    getSortableProperties,
    getSortablePropertiesWithTypes
  };
}

