import { ViewportScroller } from '@angular/common';
import { APP_INITIALIZER, Optional } from '@angular/core';
import { NG_DOC_NIGHT_THEME, NG_DOC_STORE_THEME_KEY } from '@ng-doc/app/constants';
import { NgDocThemeService, NgDocStoreService } from '@ng-doc/app/services';
import { NG_DOC_THEME, NG_DOC_DEFAULT_THEME_ID } from '@ng-doc/app/tokens';
import { asArray, isBrowser } from '@ng-doc/core';
import { provideNgDocUiKitConfig } from '@ng-doc/ui-kit';

/**
 * Provides the NgDoc application configuration.
 * @param config - The optional application configuration.
 */
function provideNgDocApp(config) {
  return [/* --- Themes --- */
  {
    provide: NG_DOC_THEME,
    useValue: NG_DOC_NIGHT_THEME,
    multi: true
  }, ...asArray(config?.themes).map(theme => ({
    provide: NG_DOC_THEME,
    useValue: theme,
    multi: true
  })), ...asArray(config?.defaultThemeId).map(themeId => ({
    provide: NG_DOC_DEFAULT_THEME_ID,
    useValue: themeId
  })), /* --- Theme Initializer--- */
  {
    provide: APP_INITIALIZER,
    useFactory: (themeService, store, defaultThemeId) => {
      return async () => {
        const savedThemeId = isBrowser ? store.get(NG_DOC_STORE_THEME_KEY) : null;
        if (defaultThemeId === 'auto' && !savedThemeId || savedThemeId === NgDocThemeService.autoThemeId) {
          return themeService.enableAutoTheme(undefined, NG_DOC_NIGHT_THEME);
        } else if (isBrowser) {
          return themeService.set(savedThemeId ?? defaultThemeId, false);
        }
      };
    },
    multi: true,
    deps: [NgDocThemeService, NgDocStoreService, [new Optional(), NG_DOC_DEFAULT_THEME_ID]]
  }, /* --- Viewport Scroller --- */
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [ViewportScroller],
    useFactory: viewportScroller => {
      return () => viewportScroller.setOffset([0, 64]);
    }
  }, /* --- UiKit --- */
  ...provideNgDocUiKitConfig(config?.uiKit)];
}

/**
 * Generated bundle index. Do not edit.
 */

export { provideNgDocApp };
