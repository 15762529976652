import { VmrThemeManager } from './theme-manager.service';
import {
  APP_INITIALIZER,
  EnvironmentProviders,
  makeEnvironmentProviders,
} from '@angular/core';
import {
  THEME_DEFAULT,
  PREFERS_COLOR_SCHEME_DARK,
  VMR_DARK_THEME_CLASS,
  VMR_LIGHT_THEME_CLASS,
  VMR_THEME_LOCAL_STORAGE_KEY,
  VMR_THEME_MANAGER_CONFIG,
  themeManagerConfig,
  type Theme,
  type VmrThemeManagerConfig
} from './theme-manager-config';

export function provideVmrThemeManager(
  config: Partial<VmrThemeManagerConfig> = {}
): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: VMR_THEME_MANAGER_CONFIG,
      useValue: themeManagerConfig(config),
    },
    {
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: initVmrThemeManager,
      deps: [VmrThemeManager, VMR_THEME_MANAGER_CONFIG]
    },
  ]);
}

function initVmrThemeManager(
  _themeManager: VmrThemeManager,
  _config: VmrThemeManagerConfig
) {
  return () =>
    new Promise<void>(resolve => {
      setThemeInitRender(_config.themeDefault);
      resolve();
    });
}

/**
 * Used to set the theme on initial page load. Provided as an `APP_INITIALIZER` it should prevent
 * the flashing of unstyled content. Fallback option is to inline this JavaScript in `index.html`.
 *
 * @param themeDefault Fallback value to use if no preference is found. Default is `light`.
 */
export function setThemeInitRender(themeDefault: Theme = THEME_DEFAULT) {
  const rootEl = document.documentElement;
  const theme = localStorage?.getItem(VMR_THEME_LOCAL_STORAGE_KEY) ?? themeDefault;

  const isDarkMode =
    theme === 'dark' ||
    ((theme === 'system' || theme === 'auto') && window.matchMedia(PREFERS_COLOR_SCHEME_DARK).matches);

  const classToAdd = isDarkMode ? VMR_DARK_THEME_CLASS : VMR_LIGHT_THEME_CLASS;
  const classToRemove = isDarkMode ? VMR_LIGHT_THEME_CLASS : VMR_DARK_THEME_CLASS;

  rootEl.classList.add(classToAdd);
  rootEl.classList.remove(classToRemove);

  localStorage?.setItem(VMR_THEME_LOCAL_STORAGE_KEY, theme);
}