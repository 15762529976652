import { NgDocBreadcrumbComponent, NgDocPageNavigationComponent, NgDocTocComponent } from '@ng-doc/app/components';
const NG_DOC_DEFAULT_PAGE_SKELETON = {
  breadcrumbs: NgDocBreadcrumbComponent,
  navigation: NgDocPageNavigationComponent,
  toc: NgDocTocComponent
};

/**
 * Generated bundle index. Do not edit.
 */

export { NG_DOC_DEFAULT_PAGE_SKELETON };
