import { createError } from '../errors.js';
import { create as avlCreate, find as avlFind, greaterThan as avlGreaterThan, insert as avlInsert, lessThan as avlLessThan, rangeSearch as avlRangeSearch, removeDocument as avlRemoveDocument } from '../trees/avl.js';
import { create as radixCreate, find as radixFind, insert as radixInsert, removeDocumentByWord as radixRemoveDocument } from '../trees/radix.js';
import { intersect } from '../utils.js';
import { BM25 } from './algorithms.js';
import { getInnerType, isArrayType } from './defaults.js';
export async function insertDocumentScoreParameters(index, prop, id, tokens, docsCount) {
  index.avgFieldLength[prop] = ((index.avgFieldLength[prop] ?? 0) * (docsCount - 1) + tokens.length) / docsCount;
  index.fieldLengths[prop][id] = tokens.length;
  index.frequencies[prop][id] = {};
}
export async function insertTokenScoreParameters(index, prop, id, tokens, token) {
  let tokenFrequency = 0;
  for (const t of tokens) {
    if (t === token) {
      tokenFrequency++;
    }
  }
  const tf = tokenFrequency / tokens.length;
  index.frequencies[prop][id][token] = tf;
  if (!(token in index.tokenOccurrencies[prop])) {
    index.tokenOccurrencies[prop][token] = 0;
  }
  // increase a token counter that may not yet exist
  index.tokenOccurrencies[prop][token] = (index.tokenOccurrencies[prop][token] ?? 0) + 1;
}
export async function removeDocumentScoreParameters(index, prop, id, docsCount) {
  index.avgFieldLength[prop] = (index.avgFieldLength[prop] * docsCount - index.fieldLengths[prop][id]) / (docsCount - 1);
  index.fieldLengths[prop][id] = undefined;
  index.frequencies[prop][id] = undefined;
}
export async function removeTokenScoreParameters(index, prop, token) {
  index.tokenOccurrencies[prop][token]--;
}
export async function calculateResultScores(context, index, prop, term, ids) {
  const documentIDs = Array.from(ids);
  // Exact fields for TF-IDF
  const avgFieldLength = index.avgFieldLength[prop];
  const fieldLengths = index.fieldLengths[prop];
  const oramaOccurrencies = index.tokenOccurrencies[prop];
  const oramaFrequencies = index.frequencies[prop];
  // oramaOccurrencies[term] can be undefined, 0, string, or { [k: string]: number }
  const termOccurrencies = typeof oramaOccurrencies[term] === 'number' ? oramaOccurrencies[term] ?? 0 : 0;
  const scoreList = [];
  // Calculate TF-IDF value for each term, in each document, for each index.
  const documentIDsLength = documentIDs.length;
  for (let k = 0; k < documentIDsLength; k++) {
    var _oramaFrequencies_id;
    const id = documentIDs[k];
    const tf = (oramaFrequencies === null || oramaFrequencies === void 0 ? void 0 : (_oramaFrequencies_id = oramaFrequencies[id]) === null || _oramaFrequencies_id === void 0 ? void 0 : _oramaFrequencies_id[term]) ?? 0;
    const bm25 = BM25(tf, termOccurrencies, context.docsCount, fieldLengths[id], avgFieldLength, context.params.relevance);
    scoreList.push([id, bm25]);
  }
  return scoreList;
}
export async function create(orama, schema, index, prefix = '') {
  if (!index) {
    index = {
      indexes: {},
      searchableProperties: [],
      searchablePropertiesWithTypes: {},
      frequencies: {},
      tokenOccurrencies: {},
      avgFieldLength: {},
      fieldLengths: {}
    };
  }
  for (const [prop, type] of Object.entries(schema)) {
    const typeActualType = typeof type;
    const path = `${prefix}${prefix ? '.' : ''}${prop}`;
    if (typeActualType === 'object' && !Array.isArray(type)) {
      // Nested
      create(orama, type, index, path);
      continue;
    }
    switch (type) {
      case 'boolean':
      case 'boolean[]':
        index.indexes[path] = {
          true: [],
          false: []
        };
        break;
      case 'number':
      case 'number[]':
        index.indexes[path] = avlCreate(0, []);
        break;
      case 'string':
      case 'string[]':
        index.indexes[path] = radixCreate();
        index.avgFieldLength[path] = 0;
        index.frequencies[path] = {};
        index.tokenOccurrencies[path] = {};
        index.fieldLengths[path] = {};
        break;
      default:
        throw createError('INVALID_SCHEMA_TYPE', Array.isArray(type) ? 'array' : type, path);
    }
    index.searchableProperties.push(path);
    index.searchablePropertiesWithTypes[path] = type;
  }
  return index;
}
async function insertScalar(implementation, index, prop, id, value, schemaType, language, tokenizer, docsCount) {
  switch (schemaType) {
    case 'boolean':
      {
        const booleanIndex = index.indexes[prop];
        booleanIndex[value ? 'true' : 'false'].push(id);
        break;
      }
    case 'number':
      avlInsert(index.indexes[prop], value, [id]);
      break;
    case 'string':
      {
        const tokens = await tokenizer.tokenize(value, language, prop);
        await implementation.insertDocumentScoreParameters(index, prop, id, tokens, docsCount);
        for (const token of tokens) {
          await implementation.insertTokenScoreParameters(index, prop, id, tokens, token);
          radixInsert(index.indexes[prop], token, id);
        }
        break;
      }
  }
}
export async function insert(implementation, index, prop, id, value, schemaType, language, tokenizer, docsCount) {
  if (!isArrayType(schemaType)) {
    return insertScalar(implementation, index, prop, id, value, schemaType, language, tokenizer, docsCount);
  }
  const innerSchemaType = getInnerType(schemaType);
  const elements = value;
  const elementsLength = elements.length;
  for (let i = 0; i < elementsLength; i++) {
    await insertScalar(implementation, index, prop, id, elements[i], innerSchemaType, language, tokenizer, docsCount);
  }
}
async function removeScalar(implementation, index, prop, id, value, schemaType, language, tokenizer, docsCount) {
  switch (schemaType) {
    case 'number':
      {
        avlRemoveDocument(index.indexes[prop], id, value);
        return true;
      }
    case 'boolean':
      {
        const booleanKey = value ? 'true' : 'false';
        const position = index.indexes[prop][booleanKey].indexOf(id);
        index.indexes[prop][value ? 'true' : 'false'].splice(position, 1);
        return true;
      }
    case 'string':
      {
        const tokens = await tokenizer.tokenize(value, language, prop);
        await implementation.removeDocumentScoreParameters(index, prop, id, docsCount);
        for (const token of tokens) {
          await implementation.removeTokenScoreParameters(index, prop, token);
          radixRemoveDocument(index.indexes[prop], token, id);
        }
        return true;
      }
  }
}
export async function remove(implementation, index, prop, id, value, schemaType, language, tokenizer, docsCount) {
  if (!isArrayType(schemaType)) {
    return removeScalar(implementation, index, prop, id, value, schemaType, language, tokenizer, docsCount);
  }
  const innerSchemaType = getInnerType(schemaType);
  const elements = value;
  const elementsLength = elements.length;
  for (let i = 0; i < elementsLength; i++) {
    await removeScalar(implementation, index, prop, id, elements[i], innerSchemaType, language, tokenizer, docsCount);
  }
  return true;
}
export async function search(context, index, prop, term) {
  if (!(prop in index.tokenOccurrencies)) {
    return [];
  }
  // Performa the search
  const rootNode = index.indexes[prop];
  const {
    exact,
    tolerance
  } = context.params;
  const searchResult = radixFind(rootNode, {
    term,
    exact,
    tolerance
  });
  const ids = new Set();
  for (const key in searchResult) {
    for (const id of searchResult[key]) {
      ids.add(id);
    }
  }
  return context.index.calculateResultScores(context, index, prop, term, Array.from(ids));
}
export async function searchByWhereClause(context, index, filters) {
  const filterKeys = Object.keys(filters);
  const filtersMap = filterKeys.reduce((acc, key) => ({
    [key]: [],
    ...acc
  }), {});
  for (const param of filterKeys) {
    const operation = filters[param];
    if (typeof operation === 'boolean') {
      const idx = index.indexes[param];
      const filteredIDs = idx[operation.toString()];
      filtersMap[param].push(...filteredIDs);
      continue;
    }
    if (typeof operation === 'string' || Array.isArray(operation)) {
      const idx = index.indexes[param];
      for (const raw of [operation].flat()) {
        const term = await context.tokenizer.tokenize(raw, context.language, param);
        const filteredIDsResults = radixFind(idx, {
          term: term[0],
          exact: true
        });
        filtersMap[param].push(...Object.values(filteredIDsResults).flat());
      }
      continue;
    }
    const operationKeys = Object.keys(operation);
    if (operationKeys.length > 1) {
      throw createError('INVALID_FILTER_OPERATION', operationKeys.length);
    }
    const operationOpt = operationKeys[0];
    const operationValue = operation[operationOpt];
    const AVLNode = index.indexes[param];
    switch (operationOpt) {
      case 'gt':
        {
          const filteredIDs = avlGreaterThan(AVLNode, operationValue, false);
          filtersMap[param].push(...filteredIDs);
          break;
        }
      case 'gte':
        {
          const filteredIDs = avlGreaterThan(AVLNode, operationValue, true);
          filtersMap[param].push(...filteredIDs);
          break;
        }
      case 'lt':
        {
          const filteredIDs = avlLessThan(AVLNode, operationValue, false);
          filtersMap[param].push(...filteredIDs);
          break;
        }
      case 'lte':
        {
          const filteredIDs = avlLessThan(AVLNode, operationValue, true);
          filtersMap[param].push(...filteredIDs);
          break;
        }
      case 'eq':
        {
          const filteredIDs = avlFind(AVLNode, operationValue) ?? [];
          filtersMap[param].push(...filteredIDs);
          break;
        }
      case 'between':
        {
          const [min, max] = operationValue;
          const filteredIDs = avlRangeSearch(AVLNode, min, max);
          filtersMap[param].push(...filteredIDs);
        }
    }
  }
  // AND operation: calculate the intersection between all the IDs in filterMap
  const result = intersect(Object.values(filtersMap));
  return result;
}
export async function getSearchableProperties(index) {
  return index.searchableProperties;
}
export async function getSearchablePropertiesWithTypes(index) {
  return index.searchablePropertiesWithTypes;
}
export async function load(raw) {
  const {
    indexes,
    searchableProperties,
    searchablePropertiesWithTypes,
    frequencies,
    tokenOccurrencies,
    avgFieldLength,
    fieldLengths
  } = raw;
  return {
    indexes,
    searchableProperties,
    searchablePropertiesWithTypes,
    frequencies,
    tokenOccurrencies,
    avgFieldLength,
    fieldLengths
  };
}
export async function save(index) {
  const {
    indexes,
    searchableProperties,
    searchablePropertiesWithTypes,
    frequencies,
    tokenOccurrencies,
    avgFieldLength,
    fieldLengths
  } = index;
  return {
    indexes,
    searchableProperties,
    searchablePropertiesWithTypes,
    frequencies,
    tokenOccurrencies,
    avgFieldLength,
    fieldLengths
  };
}
export async function createIndex() {
  return {
    create,
    insert,
    remove,
    insertDocumentScoreParameters,
    insertTokenScoreParameters,
    removeDocumentScoreParameters,
    removeTokenScoreParameters,
    calculateResultScores,
    search,
    searchByWhereClause,
    getSearchableProperties,
    getSearchablePropertiesWithTypes,
    load,
    save
  };
}

