import { blockquoteProcessor, iconProcessor, tooltipProcessor, linkProcessor, codeProcessor, demoProcessor, demoPaneProcessor, playgroundProcessor, tabsProcessor } from '@ng-doc/app/processors/processors';
const NG_DOC_DEFAULT_PAGE_PROCESSORS = [
/**
 * The order of the directives is important.
 * The higher the directive is in the list, the earlier it will be run.
 */
blockquoteProcessor, iconProcessor, tooltipProcessor, linkProcessor, codeProcessor, demoProcessor, demoPaneProcessor, playgroundProcessor, tabsProcessor];

/**
 * Generated bundle index. Do not edit.
 */

export { NG_DOC_DEFAULT_PAGE_PROCESSORS };
