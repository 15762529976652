import { NgDocSearchEngine } from '@ng-doc/app/classes/search-engine';
import { asArray } from '@ng-doc/core/helpers/as-array';
import { objectKeys } from '@ng-doc/core/helpers/object-keys';
import { create, insertMultiple } from '@orama/orama';
import { afterInsert, searchWithHighlight } from '@orama/plugin-match-highlight';
import { from } from 'rxjs';
import { map, switchMap, shareReplay } from 'rxjs/operators';

/**
 * Search engine for the documentation, it loads the index and provides a search method.
 */
class NgDocDefaultSearchEngine extends NgDocSearchEngine {
  constructor(options) {
    super();
    this.options = options;
    this.db$ = from(create({
      schema: {
        title: 'string',
        section: 'string',
        content: 'string'
      },
      components: {
        afterInsert: [afterInsert],
        tokenizer: {
          stemmer: options?.stemmer
        }
      }
    })).pipe(map(db => db), switchMap(db => this.request(`assets/ng-doc/indexes.json`).pipe(switchMap(pages => insertMultiple(db, pages)), map(() => db))), shareReplay(1));
  }
  /**
   * Search the documentation for the given query.
   * @param query The query to search for.
   */
  search(query) {
    return this.db$.pipe(switchMap(db => searchWithHighlight(db, {
      term: query,
      boost: {
        title: 4,
        section: 2
      },
      properties: ['section', 'content'],
      tolerance: this.options?.tolerance,
      exact: this.options?.exact,
      limit: this.options?.limit ?? 10
    })), map(result => result.hits.map(hit => {
      const keys = objectKeys(hit.positions);
      return {
        index: hit.document,
        positions: keys.reduce((acc, key) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          acc[key] = [...asArray(acc[key]), ...Object.values(hit.positions[key]).flat()];
          return acc;
        }, {})
      };
    })));
  }
  request(url) {
    return from(fetch(url)).pipe(switchMap(response => response.json()));
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { NgDocDefaultSearchEngine };
